import React from "react";
import { coresData } from "../../constants/data";
import CoreItem from "./CoreItem";

const CoreValues = () => {
  return (
    <div className="services w-full py-14 px-12 md:px-28 bg-red-300">
      <h1 className="small-heading">Our Core Values</h1>
      <h1 className="text-center text-white text-4xl font-bold px-0 md:px-20 mt-4">
        Key Principles and standards that guides the work process at RWNDynamics
      </h1>
      <div className="flex  justify-center   py-8 !w-full items-center">
        {coresData.map((item, index) => (
          <CoreItem item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default CoreValues;
