import React from "react";

const CoreItem = ({ item }) => {
  return (
    <div className="w-[280px] mx-3 my-4">
      {/* Img */}
      <div className="w-[100px] flex items-center justify-center py-4 rounded-md mb-6">
        <img src={item.img} alt="services" />
      </div>
      {/* Title */}
      <h1 className=" font-bold text-white ml-2 mb-3">{item.title}</h1>
      {/* Desc */}
      {/* <p className="text-xs text-white">{item.desc}</p> */}
    </div>
  );
};

export default CoreItem;
