import React from "react";
import { missionVission } from "../../constants/imports";
const MissionVision = () => {
  return (
    <div className="res-cont px-10 grid grid-cols-1 md:grid-cols-2 justify-start items-center">
      {/* Description */}
      <div className=" md:mx-10 my-2 py-5 md:pr-10 w-full">
         <h1 style={{}} className="heading">
         Vision 
        </h1>
        <p className="font-light text-customBlue text-left text-lg mt-2">
        Use technology solutions to address real-life problems.
                </p>

        <h1 style={{}} className="heading">
     Mission
        </h1>
        <p className="font-light text-customBlue text-lg text-left mt-2">
        Provide innovative and reliable technology solutions that positively impact people's lives. </p>


      </div>
      {/* Image */}
      <div className=" mx-10 flex md:justify-end justify-center">
        <img src={missionVission} alt="ABout Us" className="w-[400px]" />
      </div>
    </div>
  );
};

export default MissionVision;
